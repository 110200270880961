import styled from "styled-components"

export const Wrapper = styled.div`
  width: 100%;
  min-height: 110vh;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  position: relative;
`
export const FixedBackground = styled.div`
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
`

export const HideOverlay = styled.div`
  position: absolute;
  bottom: -1px;
  right: -1px;
  left: -1px;
  background: #151515;
  height: 0;
`
