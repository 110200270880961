import styled from "styled-components"
import {mediaMax} from "../../../helpers/MediaQueries";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  background-color: #151515;
  margin-bottom: 30vh;
  ${mediaMax.phoneXL`
    height: auto;
    padding: 96px 0 0;
    margin-bottom: 0;
  `}
`

export const Items = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10vh 10%;
  opacity: 0;
  ${mediaMax.phoneXL`
    opacity: 1;
    height: auto;
    padding: 0 24px 144px;
  `}
  .mobHeading {
    width: 100%;
    br {
      display: none;
    }
    h4 {
      padding-bottom: 18px;
    }
    p {
      padding-bottom: 35px;
    }
  }
  .c-line1 {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    h4 {
      align-self: flex-end;
      min-width: 290px;
      letter-spacing: 0.1em;
      transform: translate(0, 160px);
      ${mediaMax.phoneXL`
        transform: translate(0, 0);
      `}
    }
    img {
      width: 14.6%;
      min-width: 150px;
      margin-bottom: 48px;
      ${mediaMax.phoneXL`
        min-width: 10px;
        width: 25%;
        margin-bottom: 26px;
      `}
    }
    img:last-child {
      width: 33%;
      min-width: 338px;
      ${mediaMax.phoneXL`
        min-width: 10px;
        width: 57%;
      `}
    }
  }
  .c-line2 {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    p {
      align-self: flex-start;
      padding-top: 34px;
      transform: translate(0, 160px);
      ${mediaMax.phoneXL`
        transform: translate(0, 0);
      `}
    }
    img {
      width: 15.6%;
      min-width: 160px;
      ${mediaMax.phoneXL`
        min-width: 10px;
        width: 27%;
      `}
    }
  }
`
export const Milestones = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow-x: hidden;
  opacity: 1;
  ${mediaMax.phoneXL`
    height: auto;
    position: relative;
    align-items: flex-start;
  `}
`
export const Heading = styled.div`
  padding-bottom: 30px;
  transform: translate(400%, 0);
  opacity: 0;
  ${mediaMax.phoneXL`
    transform: translate(0, 0);
    opacity: 1;
    padding-left: 24px;
  `}
  .h3 {
    font-weight: 300;
  }
`
export const TimeLineWrapper = styled.div`
  width: 100%;
  ${mediaMax.phoneXL`
    padding-bottom: 20px;
    overflow: auto;
    width: 100vw;
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      outline: none;
    }
    &::-webkit-scrollbar-track {
      box-shadow: none;
    }
  `}
`;
export const TimeLine = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  transform: translate(200%, 0);
  opacity: 0;
  ${mediaMax.phoneXL`
      justify-content: space-between;
      padding: 0 144px 0 24px;
      width: 1200px;
      transform: translate(0, 0);
      opacity: 1;
  `}
  .tl-item {
    position: relative;
    text-align: center;
    font-family: "TT Norms", sans-serif;
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    &:after {
      content: "";
      background-color: #fff;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      position: absolute;
      left: 50%;
      top: calc(50% - 21px);
      transform: translateX(-50%);
    }
    h6 {
      padding-bottom: 42px;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.1em;
    }
    p {
      padding-top: 42px;
      text-align: center;
      font-weight: 300;
      letter-spacing: 0.1em;
    }
  }
  .tl-line {
    width: 100%;
    height: 1px;
    background-color: #fff;
    position: absolute;
    top: calc(50% - 9px);
    left: -100%;
    opacity: 0;
    ${mediaMax.phoneXL`
      width: 1200px;
      opacity: 1; 
      left: 0;
    `}
  }
`
