import React, {useEffect, useRef} from "react";
import {Wrapper, FPItem, FPImage, FPInfo, FPItems} from './index.styled'
import { FloorPlansData } from "../../../data/floor-plans"
import Image from "../../image";
import useWindowSize from "../../../helpers/windowSize";

const ContentFp = () => {
const fpItemsWrapperRef = useRef<HTMLDivElement>(null);
const fpItemsRef = useRef<HTMLDivElement>(null);
const fpItem1 = useRef<HTMLDivElement>(null);
const fpItem2 = useRef<HTMLDivElement>(null);
const fpItem3 = useRef<HTMLDivElement>(null);
const fpImg3 = useRef<HTMLDivElement>(null);
const {width} = useWindowSize();

  const fpItemShow = (fpItem: React.RefObject<HTMLDivElement>) => {
    if(fpItem.current) fpItem.current.style.opacity = '1';
    if(fpItem.current) fpItem.current.style.visibility = 'visible';
  }
  const fpItemHide = (fpItem: React.RefObject<HTMLDivElement>) => {
    if(fpItem.current) fpItem.current.style.opacity = '0';
    if(fpItem.current) fpItem.current.style.visibility = 'hidden';
  }
  useEffect(()=> {
    fpItemShow(fpItem1);
  }, [])

  return (
    <Wrapper ref={fpItemsWrapperRef}>
      <FPItems ref={fpItemsRef}>
        <FPItem ref={fpItem1}>
          <FPImage>
            <div className="label top">MAPLE&nbsp;AVENUE</div>
            <div className="label right">WOLF&nbsp;STREET</div>
            <div className="label bottom">SECRET&nbsp;GARDEN</div>
            <Image imageName={'floor-plan-l_4.png'}/>
            {width > 576 &&
              <a href={"/Maple_Terrace-FP_L4.pdf"} className="download fit" target="_blank">
                <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 5.64829H2.58621V0H6.36207V5.68966H8.79311L4.38622 10.7793L0 5.64829Z" fill="white" />
                  <path d="M8.82422 12V11.369H7.53403e-05V12H8.82422Z" fill="white" />
                </svg>
                DOWNLOAD TEST FIT
              </a>
            }
          </FPImage>
          <FPInfo>
            <div className="fp-info-level">
              <h5
                onClick={()=> {
                  fpItemShow(fpItem1)
                  fpItemHide(fpItem2)
                  fpItemHide(fpItem3)
                }}
                className={'active'}>Level 4</h5>
              <h5
                onClick={()=> {
                  fpItemShow(fpItem2)
                  fpItemHide(fpItem1)
                  fpItemHide(fpItem3)
                }}
              >Level 5</h5>
              <h5
                onClick={()=> {
                  fpItemShow(fpItem3)
                  fpItemHide(fpItem2)
                  fpItemHide(fpItem1)
                }}
              >Level 6</h5>
            </div>
            {width <= 576 &&
              <a href={"/Maple_Terrace-FP_L4.pdf"} className="download fit" target="_blank">
                <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 5.64829H2.58621V0H6.36207V5.68966H8.79311L4.38622 10.7793L0 5.64829Z" fill="white" />
                  <path d="M8.82422 12V11.369H7.53403e-05V12H8.82422Z" fill="white" />
                </svg>
                DOWNLOAD TEST FIT
              </a>
            }
            <div className="fp-info-items">
              {width > 576 ?
                FloorPlansData.floorPlan1.info.map((item, i) => (
                  <p className={"caption_t1"} key={`ids-${i}`}>
                    {item}
                  </p>
                ))
                :
                FloorPlansData.floorPlan1.infoMob.map((item, i) => (
                  <p className={"caption_t1"} key={`ids-${i}`}>
                    {item}
                  </p>
                ))
              }
            </div>
            <div className="fp-info-download">
              <a href={"/all_mt_fp.zip"} className="download all">
                <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 5.64829H2.58621V0H6.36207V5.68966H8.79311L4.38622 10.7793L0 5.64829Z" fill="white" />
                  <path d="M8.82422 12V11.369H7.53403e-05V12H8.82422Z" fill="white" />
                </svg>
                DOWNLOAD ALL
              </a>
            </div>
          </FPInfo>
        </FPItem>
        <FPItem ref={fpItem2}>
          <FPImage>
            <div className="label top">MAPLE&nbsp;AVENUE</div>
            <div className="label right">WOLF&nbsp;STREET</div>
            <div className="label bottom">SECRET&nbsp;GARDEN</div>
            <Image imageName={'floor-plan-l_5.png'}/>
            {width > 576 &&
              <a href={"/Maple_Terrace-FP_L5.pdf"} className="download fit" target="_blank">
                <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 5.64829H2.58621V0H6.36207V5.68966H8.79311L4.38622 10.7793L0 5.64829Z" fill="white" />
                  <path d="M8.82422 12V11.369H7.53403e-05V12H8.82422Z" fill="white" />
                </svg>
                DOWNLOAD TEST FIT
              </a>
            }
          </FPImage>
          <FPInfo>
            <div className="fp-info-level">
              <h5
                onClick={()=> {
                  fpItemShow(fpItem1)
                  fpItemHide(fpItem2)
                  fpItemHide(fpItem3)
                }}
              >Level 4</h5>
              <h5
                onClick={()=> {
                  fpItemShow(fpItem2)
                  fpItemHide(fpItem1)
                  fpItemHide(fpItem3)
                }}
                className={'active'}>Level 5</h5>
              <h5
                onClick={()=> {
                  fpItemShow(fpItem3)
                  fpItemHide(fpItem2)
                  fpItemHide(fpItem1)
                }}
              >Level 6</h5>
            </div>
            {width <= 576 &&
              <a href={"/Maple_Terrace-FP_L5.pdf"} className="download fit" target="_blank">
                <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 5.64829H2.58621V0H6.36207V5.68966H8.79311L4.38622 10.7793L0 5.64829Z" fill="white" />
                  <path d="M8.82422 12V11.369H7.53403e-05V12H8.82422Z" fill="white" />
                </svg>
                DOWNLOAD TEST FIT
              </a>
            }
            <div className="fp-info-items">
              {width > 576 ?
                FloorPlansData.floorPlan2.info.map((item, i) => (
                  <p className={"caption_t1"} key={`ids-${i}`}>
                    {item}
                  </p>
                ))
                :
                FloorPlansData.floorPlan2.infoMob.map((item, i) => (
                  <p className={"caption_t1"} key={`ids-${i}`}>
                    {item}
                  </p>
                ))
              }
            </div>
            <div className="fp-info-download">
              <a href={"/all_mt_fp.zip"} className="download all">
                <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 5.64829H2.58621V0H6.36207V5.68966H8.79311L4.38622 10.7793L0 5.64829Z" fill="white" />
                  <path d="M8.82422 12V11.369H7.53403e-05V12H8.82422Z" fill="white" />
                </svg>
                DOWNLOAD ALL
              </a>
            </div>
          </FPInfo>
        </FPItem>
        <FPItem ref={fpItem3}>
          <FPImage ref={fpImg3}>
            <div className="label top">MAPLE&nbsp;AVENUE</div>
            <div className="label right">WOLF&nbsp;STREET</div>
            <div className="label bottom">SECRET&nbsp;GARDEN</div>
            <Image imageName={'floor-plan-l_6.png'}/>
            {width > 576 &&
              <a href={"/Maple_Terrace-FP_L6.pdf"} className="download fit" target="_blank">
                <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 5.64829H2.58621V0H6.36207V5.68966H8.79311L4.38622 10.7793L0 5.64829Z" fill="white" />
                  <path d="M8.82422 12V11.369H7.53403e-05V12H8.82422Z" fill="white" />
                </svg>
                DOWNLOAD TEST FIT
              </a>
            }
          </FPImage>
          <FPInfo>
            <div className="fp-info-level">
              <h5
                onClick={()=> {
                  fpItemShow(fpItem1)
                  fpItemHide(fpItem2)
                  fpItemHide(fpItem3)
                }}
              >Level 4</h5>
              <h5
                onClick={()=> {
                  fpItemShow(fpItem2)
                  fpItemHide(fpItem3)
                  fpItemHide(fpItem1)
                }}
              >Level 5</h5>
              <h5
                onClick={()=> {
                  fpItemShow(fpItem3)
                  fpItemHide(fpItem2)
                  fpItemHide(fpItem1)
                }}
                className={'active'}>Level 6</h5>
            </div>
            {width <= 576 &&
              <a href={"/Maple_Terrace-FP_L6.pdf"} className="download fit" target="_blank">
                <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 5.64829H2.58621V0H6.36207V5.68966H8.79311L4.38622 10.7793L0 5.64829Z" fill="white" />
                  <path d="M8.82422 12V11.369H7.53403e-05V12H8.82422Z" fill="white" />
                </svg>
                DOWNLOAD TEST FIT
              </a>
            }
            <div className="fp-info-items">
              {width > 576 ?
                FloorPlansData.floorPlan3.info.map((item, i) => (
                  <p className={"caption_t1"} key={`ids-${i}`}>
                    {item}
                  </p>
                ))
                :
                FloorPlansData.floorPlan3.infoMob.map((item, i) => (
                  <p className={"caption_t1"} key={`ids-${i}`}>
                    {item}
                  </p>
                ))
              }
            </div>
            <div className="fp-info-download">
              <a href={"/all_mt_fp.zip"} className="download all">
                <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 5.64829H2.58621V0H6.36207V5.68966H8.79311L4.38622 10.7793L0 5.64829Z" fill="white" />
                  <path d="M8.82422 12V11.369H7.53403e-05V12H8.82422Z" fill="white" />
                </svg>
                DOWNLOAD ALL
              </a>
            </div>
          </FPInfo>
        </FPItem>

      </FPItems>

    </Wrapper>
  )
}
export default ContentFp