import React, {FC, useEffect, useRef} from "react"
import {Wrapper, Info, Img} from "./index.styled"
import img1 from "../../../assets/images/building/building_2.jpg"
import img2 from "../../../assets/images/building/building_3.jpg"
import gsap from "gsap"
import {ScrollTrigger} from "gsap/ScrollTrigger";

const ContentGem: FC = () => {
  const wrapperRef = useRef(null)
  const infoRef = useRef(null)
  const imgLRef = useRef(null)
  const imgRRef = useRef(null)

  useEffect(() => {
    const timelinesArray: any[] = []
    ScrollTrigger.matchMedia({
      "(min-width: 1023px)": () => {
        timelinesArray[timelinesArray.length] = gsap.timeline({
          scrollTrigger: {
            trigger: wrapperRef.current || "",
            start: "top+=30% bottom",
            end: "bottom+=35% bottom",
            scrub: 1,
            toggleActions: "play none none reverse",
          },
        })
          .to(
            infoRef.current || "",
            {opacity: 1, y: 0},
            "0"
          )
          .to(
            imgLRef.current,
            {opacity: 1, y: "-54%"},
            "0"
          )
          .to(
            imgRRef.current,
            {opacity: 1},
            "0"
          )
        timelinesArray[timelinesArray.length] = gsap
          .timeline({
            scrollTrigger: {
              trigger: wrapperRef.current || "",
              start: "bottom center",
              end: "bottom+=100% center",
              toggleActions: "play none none reverse",
              scrub: 1,
            },
          })
          .fromTo(
            wrapperRef.current || "",
            {opacity: 1},
            {opacity: 0},
            "0"
          )
      }
    })
    return () => {
      timelinesArray.forEach(timeline => timeline.kill())
    }
  }, [])
  return (
    <Wrapper ref={wrapperRef}>
      <div className={"gem-wrap"}>
        <Info ref={infoRef}>
          <h3 className="h2">A 1925 architectural <br/> gem reimagined</h3>
          <h3 className="h2i">as a 21st century <br/> workplace.</h3>
        </Info>
        <Img>
          <div className="img-l" ref={imgLRef}>
            <img src={img1} alt="Maple Terrace Uptown Dallas"/>
          </div>
          <div className="img-r" ref={imgRRef}>
            <img src={img2} alt="Maple Terrace Uptown Dallas"/>
          </div>
        </Img>
      </div>
    </Wrapper>
  )
}

export default ContentGem
