import styled from "styled-components";
import {mediaMax, mediaMin} from "../../../helpers/MediaQueries";

export const Wrapper = styled.div`
  background-color: #151515;
  height: 100vh;
  ${mediaMax.phoneXL`
    padding: 124px 24px 0;
    min-height: 920px;
    height: 100%;
  `}
  ${mediaMax.phoneL`
    min-height: 850px;
  `}
  .fp-info-download {
    ${mediaMax.phoneXL`
      margin: 0 auto; 
    `}
  }
  .download {
    font-family: 'TT Norms', serif;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.1em;
    text-decoration-line: underline;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    ${mediaMax.phoneXL`
      padding: 0 0 28px;
    `}
    svg {
      margin: 2px 6px 0 0;
    }
    &.all {
      text-decoration: none;
      border: 1px solid #FFFFFF;
      padding: 8px 28px;
      margin-right: 18px;
      ${mediaMax.phoneXL`
        margin: 24px auto;
        align-self: center;
      `}
    }
    ${mediaMax.desktopSmall`
        font-size: 11px;    
    `}
    ${mediaMax.phoneXL`
        font-size: 12px;    
    `}
  }
`;
export const FPItems = styled.div`
  position: relative;
  height: auto;
  ${mediaMax.phoneXL`
      position: relative;
  `}
`
export const FPItem = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  opacity: 0;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 9vh;
  transition: all .4s ease-in;
  ${mediaMax.phoneXL`
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 0;
    height: max-content;
  `}
`;
export const FPImage = styled.div`
    max-width: 80%;
    width: 100%;
    margin: 0 auto;
    position: relative;
    ${mediaMin.desktopM`
      max-width: 70%;
    `}
    ${mediaMin.desktopL`
      max-width: 65%;
    `}
    ${mediaMax.phoneXL`
      max-width: 100%;
    `}
    .label {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.1em;
        color: #FFFFFF;
        position: absolute;
        ${mediaMax.phoneXL`
            font-size: 9px;
            line-height: 11px;
            font-weight: 300;
        `}
        &.top {
            top: -2%;
            left: 50%;
            transform: translateX(-50%);
            ${mediaMax.phoneXL`
                top: -14px;
            `}
        }
        &.bottom {
            bottom: -2%;
            left: 50%;
            transform: translateX(-50%);
            ${mediaMax.phoneXL`
              bottom: -10%;
            `}
        }
        &.right {
            top: 50%;
            left: 97.5%;
            transform: translateY(-50%) rotate(90deg);
            ${mediaMax.phoneXL`
              right: -36px;
            `}
        }
    }

`;
export const FPInfo = styled.div`
    padding: 6.75vh 2.84% 0 2.84%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    ${mediaMax.phoneXL`
      padding: 0;
    `}
    .fp-info-level {
      width: 185px;
      padding-left: 18px;
      ${mediaMax.desktopSmall`
        width: 176px;
        padding: 34px 0 24px;
      `}
      ${mediaMax.phoneXL`
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-left: 0;
      `}
        h5 {
            opacity: 0.25;
            font-family: 'TT Norms', serif;
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 28px;
            color: #FFFFFF;
            letter-spacing: 0.02em;
            padding-bottom: 12px;
            cursor: pointer;
            transition: .4s ease-out;
            &:hover {
              opacity: .75;
            }
            &.active {
                opacity: 1;
            }        
        }
    }
    
    .fp-info-items {
        width: 64%;
        display: flex;
        flex-wrap: wrap;
        ${mediaMax.phoneXL`
          width: 100%;
          justify-content: space-between;
        `}
        p {
            width: 25%;
            text-transform: uppercase;
            letter-spacing: 0.1em;
            font-feature-settings: "liga" off;
            padding-bottom: 15px;
            &:first-child {
                font-weight: 700;
            }
            ${mediaMax.phoneXL`
              width: 100%;
              display: flex;
              justify-content: space-between;
              span {
                min-width: 92px;
              }
            `}
        }
    }
`;