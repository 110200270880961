import React from "react"
import why_icon_1 from "../assets/images/building/why_icon_1.svg"
import why_icon_2 from "../assets/images/building/why_icon_2.svg"
import why_icon_3 from "../assets/images/building/why_icon_3.svg"
import why_icon_4 from "../assets/images/building/why_icon_4.svg"
import why_icon_5 from "../assets/images/building/why_icon_5.png"
import why_icon_6 from "../assets/images/building/why_icon_6.svg"
import why_icon_7 from "../assets/images/building/why_icon_7.svg"
import why_icon_8 from "../assets/images/building/why_icon_8.svg"
export const WhyMP = {
  line1: [
    {
      icon: why_icon_1,
      title: (
        <>
          Soaring <br /> Ceiling Heights  <br className={'m'} /> <br /> up to 17’-0”
        </>
      ),
    },
    {
      icon: why_icon_2,
      title: (
        <>
          A Quick <br /> 1-Minute Journey <br className={'m'} /> <br /> from Garage to Desk
        </>
      ),
    },
    {
      icon: why_icon_3,
      title: (
        <>
          15,000 Square Feet of <br /> Class&nbsp;AA <br className={'m'} /> On-Site <br /> Amenities
        </>
      ),
    },
    {
      icon: why_icon_4,
      title: (
        <>
          Unparalleled Outdoor <br /> Access&nbsp;with&nbsp;a <br className={'m'} /> 9,000 SF <br /> Secret
          Garden Park
        </>
      ),
    },
  ],
  line2: [
    {
      icon: why_icon_5,
      iconClassName: "icon-5",
      title: (
        <>
          3-Minute Walk <br />  <br className={'m'} />  to/from <br /> Katy Trail
        </>
      ),
    },
    {
      icon: why_icon_6,
      title: (
        <>
          5 Certifications including <br /> LEED, WELL, WiredScore, <br />{" "}
          TrueCarbon and Fitwel
        </>
      ),
    },
    {
      icon: why_icon_7,
      title: (
        <>
          1-Minute Drive <br /> <br className={'m'} /> To / From <br /> Dallas North Tollway
        </>
      ),
    },
    {
      icon: why_icon_8,
      title: (
        <>
          CATCH and Doce Mesas <br /> located on-site <br className={'m'} /> + Private <br /> Bar and Lounge
        </>
      ),
    },
  ],
}
