import styled from "styled-components"
import {mediaMax} from "../../../helpers/MediaQueries";

export const Wrapper = styled.div`
  width: 100%;
  height: 70vh;
  padding-left: 10.3%;
  display: flex;
  align-items: center;
  background-color: #151515;
  ${mediaMax.phoneXL`
    height: 100%;
    padding: 0 24px 72px;
  `}
  .gem-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${mediaMax.phoneXL`
      flex-direction: column-reverse;
    `}
  }
`
export const Info = styled.div`
  width: 43.2%;
  opacity: 0;
  letter-spacing: 0.01em;
  transform: translateY(-100px);
  ${mediaMax.phoneXL`
    width: 100%;
    transform: translateY(0);
  `}
  ${mediaMax.phoneXL`
    opacity: 1;
  `}
  .h2 {
    padding-bottom: 8px;
  }
  .h2i {
    ${mediaMax.phoneXL`
        br {display: none}
      `}
  }
`
export const Img = styled.div`
  width: 54.6%;
  position: relative;
  height: 100%;
  margin-bottom: 10%;
  ${mediaMax.phoneXL`
    width: 100%;
    padding-bottom: 70%;  
    margin-bottom: 20%;
  `}
  img {
    display: block;
  }

  .img-l {
    width: 46.3%;
    position: absolute;
    left: 0;
    top: 40%;
    transform: translateY(0);
    z-index: 1;
    opacity: 0;
    ${mediaMax.phoneXL`
      opacity: 1;
      top: 0;
    `}
  }

  .img-r {
    width: 67.3%;
    position: absolute;
    right: 0;
    top: 60%;
    transform: translateY(0);
    overflow: hidden;
    opacity: 0;
    ${mediaMax.phoneXL`
      opacity: 1;
      top: 35%;
    `}
  }
`
