import React, { useContext, useEffect } from "react"
import { Helmet } from "react-helmet"

import { quoteData } from "../data/intro"
import { nextData } from "../data/next"
import { ImgTextData } from "../data/img-text"
import { headText } from "../data/head-text"
import ContentQuote from "../components/content-quote"
import ContentIntro from "../components/content-intro"
import ContentGem from "../components/building/content-gem"
import ContentImgText from "../components/content-img-text"
import ContentWhy from "../components/building/content-why"
import ContentCertifications from "../components/building/content-certifications"
import ContentNextSection from "../components/content-next"
import Footer from "../components/footer"
import ContentMasked from "../components/content-masked"
import ContentFp from "../components/building/content-fp"
import { Context } from "../context/context"
import ContentBuildingHeadingText from "../components/building/content-building-heading-text"
import ContentMaskedFixed from "../components/content-masked-fixed"
import FullHeightContentImgText from "../components/building/full-height-content-img-text"
import useWindowSize from "../helpers/windowSize"
import mainImage from "../assets/images/building/building_11_masked.jpg"

const IndexPage = () => {
  const { setMenuTheme } = useContext(Context)
  const { width } = useWindowSize()
  useEffect(() => {
    setMenuTheme("full-light")
  })

  return (
    <>
      <Helmet defer={false}>
        <title>Maple Terrace Office</title>
      </Helmet>
      <ContentIntro
        imgName={"building_1.jpg"}
        objPosition={"center 38% !important"}
        scrollArrow={true}
      />
      <section className={"hide-legal hide-logo"} id={"nextToIntro"}>
        <ContentQuote quote={quoteData.building1} />
      </section>
      <ContentGem />
      <FullHeightContentImgText data={ImgTextData.building1} />
      <ContentMasked
        imgName={
          width > 1023 ? "building_5_masked.jpg" : "building_5_masked_mob.jpg"
        }
      />
      <ContentBuildingHeadingText
        heading={headText.building.heading}
        text={headText.building.text}
      />
      {width > 1023 && <ContentQuote quote={quoteData.building2} />}
      <ContentMasked
        imgName={
          width > 1023 ? "building_7_masked.jpg" : "building_7_masked_mob.jpg"
        }
      />
      {width < 577 && <ContentQuote quote={quoteData.building2} />}
      <section className="mt-10">
        <ContentImgText data={ImgTextData.building2} />
      </section>
      <ContentImgText data={ImgTextData.building3} />
      <ContentImgText data={ImgTextData.building4} />
      {width > 1023 ? (
        <ContentMaskedFixed imgSrc={mainImage} />
      ) : (
        <div className={"m-pt-90"}>
          <ContentMasked imgName={"building_11_masked_mob.jpg"} />
        </div>
      )}
      <ContentWhy />
      <ContentCertifications />
      <ContentFp />
      <ContentNextSection data={nextData.building} prevBtn={false} />
      <Footer />
    </>
  )
}

export default IndexPage
