import styled from "styled-components"
import {mediaMax} from "../../../helpers/MediaQueries";

interface IStyledProps{
  headingWidth?: string | undefined
  textWidth?: string | undefined
  textHeight?: string | undefined
}
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 20vh 1.8% 0 10.2%;
  ${mediaMax.phoneXL`
    padding: 36px 24px;  
  `}
  .content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    ${mediaMax.phoneXL`
      flex-direction: column;
    `}
  }
`
export const Headings = styled.div<IStyledProps>`
  max-width: 50%;
  opacity: 0;
  transform: translate(0, -100px);
  ${mediaMax.phoneXL`
    opacity: 1;
    transform: translate(0, 0);
    max-width: 100%;
  `}
  h3 {
    font-weight: 300;
    max-width: ${props => props.headingWidth? props.headingWidth : '500px'};
    ${mediaMax.phoneXL`
      &.h3 {
        max-width: 236px;
      }
    `}
  }
`
export const Text = styled.div<IStyledProps>`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 0;
  ${mediaMax.phoneXL`
    opacity: 1;
    width: 100%;
    padding-top: 20px;
  `}
  p {
    line-height: ${props => props.textHeight? props.textHeight : '38px'};
    padding: 0 10px;
    max-width: ${props => props.textWidth? props.textWidth : '500px'};
    ${mediaMax.phoneXL`
      line-height: 30px;
      padding: 0;    
    `}
  }
`
