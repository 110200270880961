import React, {FC, useEffect, useRef} from "react"
import {Wrapper, Milestones, Items, Heading, TimeLineWrapper, TimeLine} from "./index.styled"
import {Certifications} from "../../../data/certifications"
import useWindowSize from "../../../helpers/windowSize";
import gsap from "gsap"
import {ScrollTrigger} from "gsap/ScrollTrigger"

const ContentCertifications: FC = () => {
  const wrapperRef = useRef(null)
  const itemsRef = useRef(null)
  const titleRef = useRef(null)
  const textRef = useRef(null)
  const milestoneRef = useRef(null)
  const timelineRef = useRef(null)
  const headingRef = useRef(null)
  const {width} = useWindowSize()
  useEffect(() => {
    const timelinesArray: any[] = []
    ScrollTrigger.matchMedia({
      "(min-width: 1023px)": () => {
        timelinesArray[timelinesArray.length] = gsap
          .timeline({
            scrollTrigger: {
              trigger: wrapperRef.current || "",
              start: "top+=15% bottom",
              end: "center bottom",
              scrub: true,
              toggleActions: "play none none reverse",
            }
          })
          .to(
            [titleRef.current, textRef.current] || "",
            {y: 0}
          )
          .to(
            itemsRef.current || "",
            {opacity: 1},
            "0"
          )

        timelinesArray[timelinesArray.length] = gsap
          .timeline({
            scrollTrigger: {
              trigger: wrapperRef.current || "",
              start: "center+=20% bottom",
              end: "bottom+=20% bottom",
              toggleActions: "play none none reverse",
              scrub: true,
            }
          })
          .to(
            ".tl-line",
            {opacity: 1, left: 0},
            "-=.5"
          )
          .to(
            headingRef.current || "",
            {opacity: 1, x: 0},
            ".1"
          )
          .to(
            timelineRef.current || "",
            {opacity: 1, x: 0},
            ".1"
          )
          .to(
            itemsRef.current,
            {opacity: 0},
            ".1"
          )
          .to(
            ".c-line1",
            {y: -50},
            ".1"
          )


        timelinesArray[timelinesArray.length] = gsap
          .timeline({
            scrollTrigger: {
              trigger: wrapperRef.current || "",
              start: "center+=20% top",
              end: "bottom top",
              toggleActions: "play none none reverse",
              scrub: 0.5,
            }
          })
          .to(milestoneRef.current || "",
            {
              opacity: 0
            })
      }
    })
    return () => {
      timelinesArray.forEach(timeline => timeline.kill())
    }
  }, [])

  return (
    <Wrapper ref={wrapperRef}>
        <Items ref={itemsRef}>
          {width < 577 &&
              <div className="mobHeading">
                  <h4 className="h4" ref={titleRef}>
                    {Certifications.line1.heading}
                  </h4>
                  <p className="copy_t1" ref={textRef}>
                    {Certifications.line2.desc}
                  </p>
              </div>
          }
          <div className="c-line1">
            {width > 1023 &&
                <h4 className="h4" ref={titleRef}>
                  {Certifications.line1.heading}
                </h4>
            }
            <img src={Certifications.line1.icon_1} alt="FitWel"/>
            <span/>
            <img src={Certifications.line1.icon_2} alt="True Carbon"/>
          </div>
          <div className="c-line2">
            {width > 1023 &&
                <p className="copy_t1" ref={textRef}>
                  {Certifications.line2.desc}
                </p>
            }
            <img src={Certifications.line2.icon_3} alt="Gold Seal"/>
            <img src={Certifications.line2.icon_4} alt="LEED Silver"/>
            <img src={Certifications.line2.icon_5} alt="Well Silver"/>
          </div>
        </Items>

        <Milestones ref={milestoneRef}>
          <Heading ref={headingRef}>
            <h3 className={"h3"}>
              Schedule <span className={"h3i"}>Milestones</span>
            </h3>
          </Heading>
          <TimeLineWrapper>
            <TimeLine ref={timelineRef}>
              <div className="tl-line"/>
              {Certifications.timeLine.map((item, i) => (
                <div className="tl-item" key={`id-${i}`}>
                  <h6>{item.title}</h6>
                  <p>{item.desc}</p>
                </div>
              ))}
            </TimeLine>
          </TimeLineWrapper>
        </Milestones>
    </Wrapper>
  )
}

export default ContentCertifications
