import React, {FC, useEffect, useRef} from "react"
import {Wrapper, Heading, Items} from "./index.styled"
import {WhyMP} from "../../../data/why-mp"
import gsap from "gsap"
import {ScrollTrigger} from "gsap/ScrollTrigger";

const ContentWhy: FC = () => {
  const wrapperRef = useRef(null)
  const headingsRef = useRef(null)
  const line1Ref = useRef(null)
  const line2Ref = useRef(null)

  useEffect(() => {
    const timelinesArray: any[] = []
    ScrollTrigger.matchMedia({
      "(min-width: 1023px)": () => {
        timelinesArray[timelinesArray.length] = gsap
          .timeline({
            scrollTrigger: {
              trigger: wrapperRef.current || "",
              start: "top bottom-=30%",
              end: "center bottom-=30%",
              scrub: true,
              toggleActions: "play none none reverse",
            }
          })
          .to(
            [headingsRef.current, line1Ref.current, line2Ref.current ] || "",
            {opacity: 1, y: 0}
          )

        timelinesArray[timelinesArray.length] = gsap
          .timeline({
            scrollTrigger: {
              trigger: wrapperRef.current || "",
              start: "center top+=20%",
              end: "bottom top+=20%",
              toggleActions: "play none none reverse",
              scrub: true,
            }
          })
          .to(headingsRef.current || "", {opacity: 0, y: -75})
          .to(line1Ref.current || "", {opacity: 0, y: -225}, "0")
          .to(line2Ref.current || "", {opacity: 0, y: -110}, "0")
      }
    })
    return () => {
      timelinesArray.forEach(timeline => timeline.kill())
    }
  }, [])

  return (
    <Wrapper ref={wrapperRef}>
      <Heading ref={headingsRef}>
        <h3 className={"h3"}>Why</h3>
        <h3 className={"h3i"}>Maple Terrace?</h3>
      </Heading>
      <Items>
        <div className="line1" ref={line1Ref}>
          {WhyMP.line1.map((item: any, i) => (
            <div className={`why-item ${item.iconClassName ?? ""}`} key={item.title + i}>
              <img src={item.icon} alt={item.title}/>
              <p className={"caption_t2"}>{item.title}</p>
            </div>
          ))}
        </div>
        <div className="line2" ref={line2Ref}>
          {WhyMP.line2.map((item: any, i) => (
            <div className={`why-item ${item.iconClassName ?? ""}`} key={item.title + i}>
              <img src={item.icon} alt={item.title}/>
              <p className={"caption_t2"}>{item.title}</p>
            </div>
          ))}
        </div>
      </Items>
    </Wrapper>
  )
}

export default ContentWhy
