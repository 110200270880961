import styled from "styled-components"
import {mediaMax, mediaMin} from "../../../helpers/MediaQueries";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 5vh 6% 0;
  background-color: #151515;
  ${mediaMax.phoneXL`
    padding: 14.15vh 6% 0;
    height: auto;
  `}
`
export const Heading = styled.div`
  padding-bottom: 6vh;
  opacity: 0;
  transform: translate(0, 75px);
  ${mediaMax.phoneXL`
    opacity: 1;
    transform: translate(0);
  `}
  .h3 {
    font-weight: 300;
  }
`
export const Items = styled.div`
  width: 100%;
  div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .line1 {
    transform: translate(0, 110px);
    opacity: 0;
      ${mediaMax.phoneXL`
        opacity: 1;
        transform: translate(0);
      `}
  }
  .line2 {
    transform: translate(0, 225px);
    opacity: 0;
      ${mediaMax.phoneXL`
        opacity: 1;
        transform: translate(0);
      `}
  }
  .why-item {
    width: 21%;
    padding-bottom: 7.75vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    text-align: center;
    ${mediaMax.phoneXL`
      width: 100%;
      flex-direction: row;
      justify-content: flex-start;
      text-align: left;
      align-items: center;
      flex-wrap: nowrap;
      text-transform: none;
      padding-bottom: 48px;
    `}
    &.icon-5 img {
      height: 120px;
      ${mediaMax.phoneXL`
        height: auto;
      `}
    }
    img {
      margin-bottom: 20px;
      ${mediaMax.phoneXL`
        width: 100%;
        max-width: 60px;
        padding-bottom: 0;
        margin-bottom: 0;
        margin-right: 35px;
        height: 60px;
      `}
    }
    p{
      letter-spacing: 0.05em;
      ${mediaMax.phoneXL`
        font-weight: 300;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.01em;
        max-width: 251px;

        br {
          display: none;
          &.m {
            display: block;  
          }
        }
      `}
      ${mediaMin.tablet`
        br {
          &.m {
            display: none;  
          }
        }
      `}
    }
  }
`
