import styled from "styled-components"
import {mediaMax} from "../../../helpers/MediaQueries";
interface IStyleProps {
  withPadding?: boolean | undefined
  reversed?: boolean | undefined
  infoWidth?: string | undefined
  infoPadding?: boolean | undefined
  fontWeight?: string | undefined
}
export const Wrapper = styled.div<IStyleProps>`
  width: 100%;
  height: 150vh;
  margin-bottom: 5vh;
  ${mediaMax.phoneXL`
    height: auto;
    margin-bottom: 27px;

  `}
`
export const StickyWrapper = styled.div<IStyleProps>`
  width: 100%;
  height: 100vh;
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #151515;
  padding: ${props => (props.withPadding ? "9% 10.2% 3% 10.2%" : 0)};
  flex-direction: ${props => (props.reversed ? "row-reverse" : "row")};
  ${mediaMax.phoneXL`
    flex-direction: column;
    height: auto;
  `}
`
export const Img = styled.div<IStyleProps>`
  overflow: hidden;
  width: ${props => (props.withPadding ? "52.5%" : "57.2%")};
  position: relative;
  max-width: 100%;
  ${mediaMax.phoneXL`
    width: 100%;
  `}
  .gatsby-image-wrapper {
    height: 100vh;
    object-fit: cover;
    object-position: top;
    ${mediaMax.phoneXL`
      width: 100%;
      height: auto;
    `}
  }
`
export const Info = styled.div<IStyleProps>`
  width: 42%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${mediaMax.phoneXL`
    width: 100%;
    padding: 0 24px;
  `}
  h4, p {
    min-width: 290px;
    width: 290px;
    ${mediaMax.phoneXL`
      width: 100%;
    `}
  }
  h4 {
    letter-spacing: 0.1em;
    font-weight: 300;
    ${mediaMax.phoneXL`
      width: 100%;
      padding-top: 42px;
      br:last-child {
        display: none;
      }
    `}
  }
  p {
    padding-top: 4vh;
    ${mediaMax.phoneXL`
      padding-top: 21px;
    `}
  }
`
