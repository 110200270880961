import React, {FC, useEffect, useRef} from "react"
import {Wrapper, Text, Headings} from "./index.styled"
import gsap from "gsap"
import {ScrollTrigger} from "gsap/ScrollTrigger";

interface IContentHeadingText {
  heading: {
    line1: string
    line2: string
  }
  headingWidth?: string
  text: string[]
  textWidth?: string
  textHeight?: string
}

const ContentBuildingHeadingText: FC<IContentHeadingText> = ({heading, text, textWidth, headingWidth, textHeight}) => {
  const wrapperRef = useRef(null)
  const headingRef = useRef(null)
  const textRef = useRef(null)

  useEffect(() => {
    const timelinesArray: any[] = []
    ScrollTrigger.matchMedia({
      "(min-width: 1023px)": () => {
        timelinesArray[timelinesArray.length] = gsap
          .timeline({
            scrollTrigger: {
              trigger: wrapperRef.current || "",
              start: "top center+=15%",
              end: "center center+=15%",
              scrub: true,
              toggleActions: "play none none reverse",
              preventOverlaps: true,
            },
          })
          .to(
            headingRef.current || "",
            {opacity: 1, y: 0}
          )
          .to(
            textRef.current || "",
            {opacity: 1, y: 0},
            "0"
          )
        timelinesArray[timelinesArray.length] = gsap
          .timeline({
            scrollTrigger: {
              trigger: wrapperRef.current || "",
              start: "top+=10 top",
              end: "bottom-=20% top",
              scrub: true,
              toggleActions: "play none none reverse",
              preventOverlaps: true,
            },
          })
          .to(
            headingRef.current || "",
            {opacity: 0}
          )
          .to(
            textRef.current || "",
            {opacity: 0, y: -100},
            "0"
          )
      }
    })
    return () => {
      timelinesArray.forEach(timeline => timeline.kill())
    }
  }, [])

  return (
    <Wrapper ref={wrapperRef}>
      <div className="content">
        <Headings ref={headingRef} headingWidth={headingWidth}>
          <h3 className={"h3"}>{heading.line1}</h3>
          <h3 className={"h3i"}>{heading.line2}</h3>
        </Headings>
        <Text ref={textRef} textWidth={textWidth} textHeight={textHeight}>
          {text.map((item, i) => (
            <p className="copy_t1" key={`id-${i}`}>
              {item}
            </p>
          ))}
        </Text>
      </div>
    </Wrapper>
  )
}

export default ContentBuildingHeadingText
