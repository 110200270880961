import React, {FC, useEffect, useRef} from "react"
import {Wrapper, Info, Img, StickyWrapper} from "./index.styled"
import Image from "../../image"
import gsap from "gsap"
import {HideOverlay} from "../../content-slider/index.styled";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import useWindowSize from '../../../helpers/windowSize'

interface IContentImgText {
  data: {
    img: string
    imgMob: string
    title: string | React.ReactElement
    title2?: string | React.ReactElement
    text: string | React.ReactElement
    title1Heading?: string
    title2Heading?: string
    withPadding?: boolean
    reversed?: boolean
    infoWidth?: string
    fontWeight?: string
    infoPadding?: boolean
  }
}

const FullHeightContentImgText: FC<IContentImgText> = ({data}) => {
  const wrapperRef = useRef(null)
  const imgWrapperRef = useRef(null)
  const infoWrapperRef = useRef(null)
  const overlayRef = useRef(null)
  const {width} = useWindowSize()
  useEffect(() => {
    const timelinesArray: any[] = []
    ScrollTrigger.matchMedia({
      "(min-width: 1023px)": () => {
        timelinesArray[timelinesArray.length] = gsap
          .timeline({
            scrollTrigger: {
              trigger: wrapperRef.current || "",
              start: "top+=10% bottom",
              end: "bottom-=30% bottom",
              scrub: 2,
              toggleActions: "play none none reverse"
            },
          })
          .fromTo(
            imgWrapperRef.current || "",
            {y: 750, opacity: 0},
            {y: 0, opacity: 1},
            "0"
          )
          .fromTo(
            infoWrapperRef.current || "",
            {opacity: 0, y: 100},
            {opacity: 1, y: 0},
            "0"
          )
        timelinesArray[timelinesArray.length] = gsap
          .timeline({
            scrollTrigger: {
              trigger: wrapperRef.current || "",
              start: "bottom bottom +=5%",
              end: "bottom+=1% top",
              toggleActions: "play none none reverse",
              scrub: 2
            }
          })
          .to(overlayRef.current || "",
            {
              height: "50%"
            }
          )

      }
    })
    return () => {
      timelinesArray.forEach(timeline => timeline.kill())
    }
  }, [])

  return (
    <Wrapper
      withPadding={data.withPadding}
      reversed={data.reversed}
      ref={wrapperRef}
    >
      <StickyWrapper
        withPadding={data.withPadding}
        reversed={data.reversed}
      >
        <Img withPadding={data.withPadding} ref={imgWrapperRef} className={"content-img-block"}>
          <Image imageName={width > 1023 ? data.img : data.imgMob}/>
          <HideOverlay ref={overlayRef}/>
        </Img>
        <Info
          reversed={data.reversed}
          ref={infoWrapperRef}
          infoWidth={data.infoWidth}
          infoPadding={data.infoPadding}
          fontWeight={data.fontWeight}
        >
          <h4 className={data.title1Heading || "h4"}>{data.title}</h4>
          {data.title2 && (
            <h4 className={data.title2Heading || "h4i"}>{data.title2}</h4>
          )}
          <p className="copy_t1">{data.text}</p>
        </Info>
      </StickyWrapper>
    </Wrapper>
  )
}

export default FullHeightContentImgText
