import React, {FC, useEffect, useRef} from "react"
import {FixedBackground, HideOverlay, Wrapper} from "./index.styled"
import gsap from "gsap";

interface IContentIntro {
  imgSrc: Element
}

const ContentMaskedFixed: FC<IContentIntro> = ({imgSrc}) => {
  const contentWrapRef = useRef(null)
  const fixedBackgroundRef = useRef(null)
  const overlayRef = useRef(null)

  useEffect(() => {
    const timelinesArray: any[] = []

    timelinesArray[timelinesArray.length] = gsap.timeline({
      scrollTrigger: {
        trigger: contentWrapRef.current || "",
        start: "bottom bottom",
        end: "bottom+=1 bottom",
        onEnter: ()=> gsap.to(fixedBackgroundRef.current, {backgroundAttachment: "unset"}),
        onEnterBack: ()=> gsap.to(fixedBackgroundRef.current, {backgroundAttachment: "fixed"})
      },
    })

    timelinesArray[timelinesArray.length] = gsap.timeline({
      scrollTrigger: {
        trigger: contentWrapRef.current || "",
        toggleActions: "play none none reverse",
        start: "top+=20% top",
        end: "bottom top",
        scrub: 0.5
      },
    }).fromTo(overlayRef.current || "",
      {
        height: 0
      },
      {
        height: "30%"
      }
    )
    return () => {
      timelinesArray.forEach(timeline => timeline.kill())
    }
  }, [])
  return (
    <Wrapper ref={contentWrapRef}>
        <FixedBackground style={{backgroundImage: `url(${imgSrc})`}} ref={fixedBackgroundRef}/>
        <HideOverlay ref={overlayRef}/>
    </Wrapper>
  )
}

export default ContentMaskedFixed
